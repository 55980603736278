import axios from '@axios'
import useJwt from "@/auth/jwt/useJwt";

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'Business',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        useJwt.get('event', {
            params: {
              calendars: calendars.join(','),
            },
          })
          .then(response =>{
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        useJwt
          .post('event', { 
            title: event.title,
            allDay: event.allDay?1:0,
            startDate: event.start,
            endDate: event.end,
            url: event.extendedProps.eventUrl,
            extendedProps: JSON.stringify(event.extendedProps)
           })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        useJwt
          .put(`event/${event.id}`, { 
            id: event.id,
            title: event.title,
            allDay: event.allDay?1:0,
            startDate: event.start,
            endDate: event.end,
            url: event.extendedProps.eventUrl,
            extendedProps: JSON.stringify(event.extendedProps)
           })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt
          .delete(`event/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
